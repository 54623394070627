import * as React from 'react'

export interface ConditionalWrapperProps extends React.PropsWithChildren {
    condition: boolean
    wrapper1: CallableFunction
    wrapper2: CallableFunction
}

export const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
    condition,
    wrapper1,
    wrapper2,
    children,
}) => (condition ? wrapper1(children) : wrapper2(children))
