import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { WorkReference } from './workreference'
import * as workReferencesStyles from './workreferences.module.css'

export const WorkReferences = () => {
    const { t }: { t: any } = useTranslation()
    const data: any = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    customers {
                        name
                        link
                        image
                        variant
                    }
                    reviews {
                        customer
                        name
                        quote
                    }
                }
            }
        }
    `)
    return (
        <>
            <div className={workReferencesStyles.container}>
                {data.site.siteMetadata.customers
                    .filter((c: any) => !c.variant)
                    .map((c: any, i: number) => (
                        <WorkReference
                            key={i}
                            title={c.name}
                            link={c.link}
                            image={c.image}
                        />
                    ))}
            </div>
            <div className={workReferencesStyles.container}>
                {data.site.siteMetadata.customers
                    .filter((c: any) => c.variant)
                    .map((c: any, i: number) => (
                        <WorkReference
                            key={i}
                            title={c.name}
                            link={c.link}
                            image={c.image}
                            variant={c.variant}
                        />
                    ))}
            </div>
        </>
    )
}
