import * as React from 'react'
import * as iconBoxStyles from './iconbox.module.css'
import * as globalStyles from '../styles/global.module.css'
import { Icon } from './icon'
import { ConditionalWrapper } from './conditionalwrapper'
import { Link } from './link'

export const IconBoxWrapper: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    return <div className={iconBoxStyles.wrapper}>{children}</div>
}

export interface IconBoxProps extends React.PropsWithChildren {
    icon?: string
    image?: string
    header?: string
    link?: string
}

export const IconBox: React.FC<IconBoxProps> = ({ icon, image, header, link, children }) => {
    return (
        <ConditionalWrapper
            condition={!!link}
            wrapper1={(children: React.ReactNode) => (
                <Link to={link} className={iconBoxStyles.container}>
                    {children}
                </Link>
            )}
            wrapper2={(children: React.ReactNode) => (
                <div className={iconBoxStyles.container}>{children}</div>
            )}
        >
            {icon && (
                <div className={iconBoxStyles.icon}>
                    <Icon name={icon} />
                </div>
            )}
            {image && (
                <div className={iconBoxStyles.image}>
                    <img src={image} className={globalStyles.contain}/>
                </div>
            )}
            <div className={iconBoxStyles.content}>
                {header && <h3>{header}</h3>}
                {children}
            </div>
        </ConditionalWrapper>
    )
}
