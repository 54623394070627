import * as React from 'react'
import { Page } from '../components/page'
import { Seo } from '../components/seo'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import { CallToAction } from '../components/call-to-action'
import { Teaser } from '../components/teaser'
import { Rocket } from '../components/rocket'
import { Content } from '../components/content'
import { WorkReferences } from '../components/workreferences'
import { IconBoxWrapper, IconBox } from '../components/iconbox'
import { Products } from '../components/products'

const IndexPage = () => {
    const { t }: { t: any } = useTranslation()

    return (
        <Page pageName={'INDEX'}>
            <Seo title={t('PAGES.INDEX.TITLE')} />
            <Teaser pageName={'INDEX'} image={'/images/teasers/spacex-6SbFGnQTE8s-unsplash.jpg'}>
                {/* <Rocket /> */}
            </Teaser>
            <Content highlight="dark">
                <h2>{t('PAGES.INDEX.WHYUS_HEADER')}</h2>
                <IconBoxWrapper>
                    <IconBox icon="school" header={t('PAGES.INDEX.WHYUS_EXPERIENCED_HEADER')}>
                        {t('PAGES.INDEX.WHYUS_EXPERIENCED_TEXT')}
                    </IconBox>
                    <IconBox icon="model_training" header={t('PAGES.INDEX.WHYUS_INNOVATIVE_HEADER')}>
                        {t('PAGES.INDEX.WHYUS_INNOVATIVE_TEXT')}
                    </IconBox>
                    <IconBox icon="description" header={t('PAGES.INDEX.WHYUS_TRANSPARENT_HEADER')}>
                        {t('PAGES.INDEX.WHYUS_TRANSPARENT_TEXT')}
                    </IconBox>
                    <IconBox icon="rocket_launch" header={t('PAGES.INDEX.WHYUS_AGILE_HEADER')}>
                        {t('PAGES.INDEX.WHYUS_AGILE_TEXT')}
                    </IconBox>
                    <IconBox icon="bookmark_added" header={t('PAGES.INDEX.WHYUS_FUTUREPROOF_HEADER')}>
                        {t('PAGES.INDEX.WHYUS_FUTUREPROOF_TEXT')}
                    </IconBox>
                    <IconBox icon="next_plan" header={t('PAGES.INDEX.WHYUS_LONGTERM_HEADER')}>
                        {t('PAGES.INDEX.WHYUS_LONGTERM_TEXT')}
                    </IconBox>
                </IconBoxWrapper>
            </Content>
            <Content>
                <h2>{t('PAGES.INDEX.EXPERTISE_HEADER')}</h2>
                <IconBoxWrapper>
                    <IconBox icon="hub" header={t('PAGES.INDEX.EXPERTISE_API_HEADER')}>
                        {t('PAGES.INDEX.EXPERTISE_API_TEXT')}
                    </IconBox>
                    <IconBox icon="published_with_changes" header={t('PAGES.INDEX.EXPERTISE_REBUILD_HEADER')}>
                        {t('PAGES.INDEX.EXPERTISE_REBUILD_TEXT')}
                    </IconBox>
                    <IconBox icon="settings_suggest" header={t('PAGES.INDEX.EXPERTISE_INDIVIDUAL_HEADER')}>
                        {t('PAGES.INDEX.EXPERTISE_INDIVIDUAL_TEXT')}
                    </IconBox>
                    <IconBox icon="construction" header={t('PAGES.INDEX.EXPERTISE_IMPROVEMENT_HEADER')}>
                        {t('PAGES.INDEX.EXPERTISE_IMPROVEMENT_TEXT')}
                    </IconBox>
                    <IconBox icon="policy" header={t('PAGES.INDEX.EXPERTISE_SECURITY_HEADER')}>
                        {t('PAGES.INDEX.EXPERTISE_SECURITY_TEXT')}
                    </IconBox>
                    <IconBox icon="dns" header={t('PAGES.INDEX.EXPERTISE_SYSTEM_HA_HEADER')}>
                        {t('PAGES.INDEX.EXPERTISE_SYSTEM_HA_TEXT')}
                    </IconBox>
                </IconBoxWrapper>
            </Content>
            <CallToAction />
            <Content highlight="dark" column>
                <h2>{t('PAGES.INDEX.PRODUCTS')}</h2>
                <Products />
            </Content>
            <Content column>
                <WorkReferences />
            </Content>
        </Page>
    )
}

export default IndexPage

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
