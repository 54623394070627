import * as React from 'react'
import classNames from 'classnames'
import * as iconStyles from './icon.module.css'

export interface IconProps extends React.PropsWithChildren {
    name: string
    className?: string
}

export const Icon: React.FC<IconProps> = ({ name, className, ...props }) => {
    return (
        <span
            className={classNames(
                'material-icons-outlined',
                iconStyles.icon,
                className
            )}
            {...props}
        >
            {name}
        </span>
    )
}
