import * as React from 'react'
import * as callToActionStyles from './call-to-action.module.css'
import { MaxWidthWrapper } from './max-width-wrapper'
import { Button } from './button'
import { useTranslation } from 'react-i18next'

export function CallToAction() {
    const { t }: { t: any } = useTranslation()
    return (
        <section className={callToActionStyles.container}>
            <MaxWidthWrapper flex className={callToActionStyles.wrapper}>
                <div className={callToActionStyles.content}>
                    <h2>{t('CTA.HEADER')}</h2>
                    <p>{t('CTA.TEXT')}</p>
                </div>
                <Button className={callToActionStyles.button} to={"mailto:ask@voltane.eu"}>
                    {t('CTA.LINKTEXT')}
                </Button>
            </MaxWidthWrapper>
        </section>
    )
}
