import * as React from 'react'
import classNames from 'classnames'
import * as globalStyles from '../styles/global.module.css'
import * as workReferenceStyles from './workreference.module.css'

export interface WorkReferenceProps {
    title: string
    link?: string
    image: string
    variant?: string
    className?: string
}

export const WorkReference: React.FC<WorkReferenceProps> = ({ title, link, image, variant, className }) => {
    return (
        <div className={classNames(workReferenceStyles.container, variant ? workReferenceStyles[variant] : null, className)}>
            {(link && (
                <a href={link} target={'_blank'} rel={'noreferrer'}>
                    <img src={image} className={globalStyles.contain}/>
                </a>
            )) ||
                <img src={image} className={globalStyles.contain}/>}
        </div>
    )
}
