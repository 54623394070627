import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { IconBox } from './iconbox'
import * as ProductsStyles from './products.module.css'

export const Products = () => {
    const { t }: { t: any } = useTranslation()
    const data: any = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    products {
                        name
                        description
                        icon
                    }
                }
            }
        }
    `)
    return (
        <>
            <div className={ProductsStyles.container}>
                {data.site.siteMetadata.products.map((p: any, i: number) => (
                    <IconBox
                        key={i}
                        link={p.link}
                        icon={p.icon}
                        image={p.image}
                        header={p.name}
                    >
                        <ReactMarkdown>{t(p.description)}</ReactMarkdown>
                    </IconBox>
                ))}
            </div>
        </>
    )
}
